import React, { useState, useContext } from "react"
import routeVideo from "../videos/focusParalane.mp4"
import { useStaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Img from "gatsby-image"
import CartContext from "../contexts/CartContext"

import styles from "./route.module.scss"
import Seo from "../components/seo"

import Layout from "../components/layout"
import SwiperSlider from "../components/swiperSlider"

const Route = () => {
  const [activeSizeOption, setActiveSizeOption] = useState("M")
  const [activeTab, setActiveTab] = useState(1)
  const [geometryToggle, setGeometryToggle] = useState(true)
  const { addItem } = useContext(CartContext)
  const data = useStaticQuery(graphql`
    query {
      allStrapiRoute(filter: { titre: { eq: "Focus Paralane 9.6" } }) {
        nodes {
          images {
            url
          }
        }
      }
      moteur: file(relativePath: { eq: "images/moteur/moteur.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      batterie: file(relativePath: { eq: "images/batterie/batterie.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paralaneGeometry: file(
        relativePath: { eq: "images/route/paralaneGeometry.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="Les vélos de route électriques"
        description="Sélection des vélos de route électriques"
      />
      <main id="topOfPage" className={styles.mainContainer}>
        <section className={styles.topSection}>
          <h4 className="dispo">En stock</h4>
          <h1 className="category">Vélos Route</h1>
        </section>
        <div className={styles.upperBracket}></div>
        <section className={styles.bikeVisuals}>
          <div className={styles.frame}>
            <SwiperSlider data={data.allStrapiRoute} />
          </div>
        </section>
        <div className={styles.lowerBracket}></div>
        <section className={styles.bikeSpecs}>
          <section className={styles.specs1}>
            <h3>
              Focus Paralane <br /> 9.6
            </h3>
            <ul>
              <AnchorLink to="/route#moteur">
                <li>
                  <div className={styles.cross}></div>
                  <p>Moteur Bosch CX 250</p>
                </li>
              </AnchorLink>
              <AnchorLink to="/route#batterie">
                <li>
                  <div className={styles.cross}></div>
                  <p>Batterie Bosch 625 wh</p>
                </li>
              </AnchorLink>
              <AnchorLink to="/route#freins">
                <li>
                  <div className={styles.cross}></div>
                  <p>Gravel puissant et léger !</p>
                </li>
              </AnchorLink>
              <li>
                <div className={styles.circle}></div>
                <p>Jantes DT Swiss RR521 </p>
              </li>
              <li>
                <div className={styles.circle}></div>
                <p>Freins à disque hydrauliques Shimano 105</p>
              </li>
              <li>
                <div className={styles.circle}></div>
                <p>Poids(modèle M) : 14kg </p>
              </li>
            </ul>
          </section>
          <section className={styles.specs2}>
            <h4 className={styles.price}>3 799,00 €</h4>
            <section className={styles.quantity}>
              <label htmlFor="taille">Taille</label>
              <section className={styles.quantityOptionsContainer}>
                <div className={styles.selectionShape}></div>
                <div
                  onClick={e => setActiveSizeOption("XS")}
                  onKeyDown={e => e.key === 1 ?? setActiveSizeOption("XS")}
                  tabIndex="0"
                  role="button"
                  className={
                    activeSizeOption !== "XS"
                      ? styles.options
                      : `${styles.options} ${styles.activeOption}`
                  }
                >
                  XS
                </div>
                <div
                  onClick={e => setActiveSizeOption("S")}
                  onKeyDown={e => e.key === 2 ?? setActiveSizeOption("S")}
                  tabIndex="0"
                  role="button"
                  className={
                    activeSizeOption !== "S"
                      ? styles.options
                      : `${styles.options} ${styles.activeOption}`
                  }
                >
                  S
                </div>
                <div
                  onClick={e => setActiveSizeOption("M")}
                  onKeyDown={e => e.key === 3 ?? setActiveSizeOption("M")}
                  tabIndex="0"
                  role="button"
                  className={
                    activeSizeOption !== "M"
                      ? styles.options
                      : `${styles.options} ${styles.activeOption}`
                  }
                >
                  M
                </div>
                <div
                  onClick={e => setActiveSizeOption("L")}
                  onKeyDown={e => e.key === 4 ?? setActiveSizeOption("L")}
                  tabIndex="0"
                  role="button"
                  className={
                    activeSizeOption !== "L"
                      ? styles.options
                      : `${styles.options} ${styles.activeOption}`
                  }
                >
                  L
                </div>
                <div
                  onClick={e => setActiveSizeOption("XL")}
                  onKeyDown={e => e.key === 5 ?? setActiveSizeOption("XL")}
                  tabIndex="0"
                  role="button"
                  className={
                    activeSizeOption !== "XL"
                      ? styles.options
                      : `${styles.options} ${styles.activeOption}`
                  }
                >
                  XL
                </div>
              </section>
            </section>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Focus Paralane 9.6",
                  `${activeSizeOption}`,
                  1,
                  3799,
                  "route/route.jpg"
                )
              }
              value="Acheter"
            />
          </section>
        </section>
        <section id="moteur" className={styles.specs3}>
          <article className={styles.specs3a}>
            <h2>Moteur Bosh</h2>
            <br />
            <section className={styles.frame2}>
              <Img
                className={styles.image}
                fluid={data.moteur.childImageSharp.fluid}
                alt="moteur pour vélo électrique de la marque Bosch"
              />
            </section>
            <p className={styles.text}>
              La motorisation Performance Line CX garantit une expérience unique
              à VTT AE : à la fois compacte, légère et plus puissante, elle
              associe des matériaux de qualité supérieure, un moteur extrêmement
              performant et des capteurs haut de gamme. Avec elle, le VTT AE
              atteint une toute nouvelle dimension. À partir de l'année-modèle
              2021, elle offre un couple pouvant atteindre 85 Nm, de nouvelles
              fonctions de gestion du moteur et un mode eMTB amélioré, tout cela
              sous la forme d'une mise à jour du logiciel. Grâce à son
              expérience de conduite plus naturelle, plus intuitive et plus
              puissante, elle constitue une référence absolue dans sa catégorie.
            </p>
          </article>
        </section>
        <section id="batterie" className={styles.specs4}>
          <article className={styles.specs4a}>
            <h2>Batterie 500wh</h2>
            <br />
            <section className={styles.frame3}>
              <Img
                className={styles.image}
                fluid={data.batterie.childImageSharp.fluid}
                alt="batterie pour vélo électrique de la marque Bosch"
              />
            </section>
            <p className={styles.text}>
              La PowerTube 500 permet aux VTT et vélos de trekking de parcourir
              sans problème de longues distances. Grâce à ses dimensions
              compactes et à sa structure minimaliste, cette batterie s'intègre
              parfaitement au design du VAE. La technique sophistiquée des
              batteries lithium-ion pouvant être intégrées au cadre garantit le
              plaisir de rouler en toute liberté. De plus, une sécurité les
              empêche de tomber, même sur terrains difficiles. Les batteries se
              retirent toutefois très aisément.
            </p>
          </article>
        </section>
        <section className={styles.upSection}>
          <AnchorLink className={styles.topOfPage} to="/route#topOfPage">
            <div className={styles.upArrow}></div>
          </AnchorLink>
        </section>
        <section id="freins" className={styles.specs5}>
          <article>
            <h2>Gravel puissant et léger</h2>
            <br />
            <p className={styles.text}>
              Le vélo de gravel électrique FOCUS Paralane 9.6 s'adapte
              parfaitement à de nombreuses situations. Polyvalent, il peut être
              utilisé à la fois comme vélotaf et pour les longues sorties sur
              routes et chemins. Totalement intégrés au tube inférieur, une
              batterie et un moteur Fazua Evation délivrent une puissance de
              250W et une excellente autonomie. Doté d'un cadre et d'une fourche
              en carbone, le Paralane est un vélo léger et confortable grâce à
              sa longue douille de direction qui permet d'avoir une position
              redressée pour soulager votre dos. De plus, avec la possibilité de
              monter des pneus de 35 mm, cela laisse une multitude
              d'utilisations. Le routage interne des câbles donne un look épuré
              au vélo et la technologie R.A.T permet un changement de roues très
              rapide malgré les axes traversant.
            </p>
          </article>
        </section>
        <section className={styles.videoSection}>
          <section className={styles.videoContainer}>
            <video controls>
              <source src={routeVideo} type="video/mp4" />
              <track
                label="English"
                kind="captions"
                srclang="en"
                default
              ></track>
            </video>
          </section>
        </section>
        <section className={styles.specs6}>
          <section className={styles.tabTitles}>
            <div
              role="button"
              tabIndex="0"
              onClick={() => setActiveTab(1)}
              onKeyDown={e => e.key === "a" ?? setActiveTab(1)}
              className={
                activeTab === 1 ? styles.activeTabTitle1 : styles.tabTitle
              }
            >
              Equipement
            </div>
            <div
              role="button"
              tabIndex="0"
              onClick={() => setActiveTab(2)}
              onKeyDown={e => e.key === "b" ?? setActiveTab(2)}
              className={
                activeTab === 2 ? styles.activeTabTitle2 : styles.tabTitle
              }
            >
              Moteur et <br /> batterie
            </div>
            <div
              role="button"
              tabIndex="0"
              onClick={() => setActiveTab(3)}
              onKeyDown={e => e.key === "c" ?? setActiveTab(3)}
              className={
                activeTab === 3 ? styles.activeTabTitle3 : styles.tabTitle
              }
            >
              Géométrie
            </div>
          </section>
          <section className={styles.tabs}>
            {activeTab === 1 && (
              <section className={styles.tab1}>
                <table>
                  <tbody>
                    <tr>
                      <th>Couleur</th>
                      <td>action team</td>
                    </tr>
                    <tr>
                      <th>Cadre</th>
                      <td>
                        C:62 Monocoque Advanced Twin Mold Technology, Aluminum
                        6061 T6 Rear Triangle, Efficient Trail Control, FSP
                        4-Link, Agile Trail Geometry, Boost 148, Full Integrated
                        Battery
                      </td>
                    </tr>
                    <tr>
                      <th>Fourche</th>
                      <td>
                        Fox 36 Float Factory FIT4, 3-Position w/Low Speed
                        Compression Adjust, Tapered, 15x110mm, E-Bike Optimized,
                        150mm, Kashima Coated
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Amort-
                        <br />
                        isseur
                      </th>
                      <td>
                        Fox Float DPX2 Factory EVOL, 185x55mm,
                        Open(Adjustable)/Medium/Firm Mode, Trunnion Mount,
                        Kashima Coated
                      </td>
                    </tr>
                    <tr>
                      <th>Freins</th>
                      <td>Shimano XT BR-M8120, Hydr. Disc Brake (203/203)</td>
                    </tr>
                    <tr>
                      <th>Selle</th>
                      <td>Natural Fit Venec</td>
                    </tr>
                    <tr>
                      <th>Tige de selle</th>
                      <td>
                        Fox Transfer Factory 31.6mm, Kashima Coated (XS, S:
                        100mm, M: 125mm, L: 150mm, XL: 175mm)
                      </td>
                    </tr>
                    <tr>
                      <th>Pédalier</th>
                      <td>
                        e*thirteen Plus Crank, 165mm (27.5: 36T // 29: 34T)
                      </td>
                    </tr>
                    <tr>
                      <th>Dérailleur arrière</th>
                      <td>Shimano XT RD-M8100-SGS, ShadowPlus, 12-Speed</td>
                    </tr>
                    <tr>
                      <th>Cassette</th>
                      <td>Shimano Deore CS-M6100, 10-51T</td>
                    </tr>
                    <tr>
                      <th>Pneu avant</th>
                      <td>
                        Schwalbe Magic Mary, Super Trail, Addix Soft, Kevlar,
                        2.4
                      </td>
                    </tr>
                    <tr>
                      <th>Pneu arrière</th>
                      <td>
                        Schwalbe Big Betty, Super Gravity, Addix Soft, Kevlar,
                        2.4
                      </td>
                    </tr>
                    <tr>
                      <th>Ecran</th>
                      <td>Bosch Nyon</td>
                    </tr>
                    <tr>
                      <th>Taille des roues</th>
                      <td>
                        Size Split: 27.5: XS (15") // 29: S (16"), M (18"), L
                        (20"), XL (22")
                      </td>
                    </tr>
                    <tr>
                      <th>Poids total</th>
                      <td>à partir de 24,3 kg</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )}

            {activeTab === 2 && (
              <section className={styles.tab2}>
                <h2>Moteur</h2>
                <table>
                  <tbody>
                    <tr>
                      <th>Type de transmission</th>
                      <td>Dérailleur/Moyeu</td>
                    </tr>
                    <tr>
                      <th>Niveau d'assistance (%)</th>
                      <td>
                        TURBO: 340 <br />
                        eMTB: 140-340 <br />
                        TOUR: 140 <br />
                        ECO: 60
                      </td>
                    </tr>
                    <tr>
                      <th>Couple maximal possible (Nm)</th>
                      <td>
                        TURBO: 85 <br />
                        eMTB: 85 <br />
                        TOUR: 50 <br />
                        ECO: 40
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Assistance jusque dans les fréquences
                        <br />
                        de pédalage maximales (tr/min)
                      </th>
                      <td>&gt; 120</td>
                    </tr>
                    <tr>
                      <th>Comportement au démarrage</th>
                      <td>Très sportif</td>
                    </tr>
                    <tr>
                      <th>Assistance maximale jusqu'à</th>
                      <td>25 km/h</td>
                    </tr>
                    <tr>
                      <th>Détection des changements de vitesse</th>
                      <td>Dynamique</td>
                    </tr>
                    <tr>
                      <th>Poids</th>
                      <td>2,9 kg</td>
                    </tr>
                  </tbody>
                </table>
                <h2>Batterie</h2>
                <table className={styles.batterieTable}>
                  <thead>
                    <tr>
                      <th aria-label="thead"></th>
                      <th>Compact Charger</th>
                      <th>Standard Charger</th>
                      <th>Fast Charger</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>50 % Charge</th>
                      <td>env. 4,2h</td>
                      <td>env. 2,1h</td>
                      <td>env. 1,4h</td>
                    </tr>
                    <tr>
                      <th>100 % charge</th>
                      <td>env. 8,8h</td>
                      <td>env. 4,9h</td>
                      <td>env. 3,7h</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )}
            {activeTab === 3 && (
              <section className={styles.tab3}>
                <section className={styles.switchSection}>
                  <h3>Tableau</h3>
                  <div
                    aria-label="toggleTabletoDrawing"
                    role="button"
                    tabIndex="0"
                    onClick={() => setGeometryToggle(!geometryToggle)}
                    onKeyDown={e =>
                      e.key === "t"
                        ? setGeometryToggle(!geometryToggle)
                        : setGeometryToggle(geometryToggle)
                    }
                    className={
                      geometryToggle
                        ? styles.tgl
                        : `${styles.tgl} ${styles.tglRight}`
                    }
                  ></div>
                  <h3>Dessin technique</h3>
                </section>
                {geometryToggle && (
                  <section className={styles.cubeStereoGeometryTable}>
                    <table>
                      <thead>
                        <tr>
                          <th aria-label="thead2"></th>
                          <th>16"</th>
                          <th>18"</th>
                          <th>20"</th>
                          <th>22"</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Tube de selle</th>
                          <td>375</td>
                          <td>420</td>
                          <td>470</td>
                          <td>520</td>
                        </tr>
                        <tr>
                          <th>Tube supérieur</th>
                          <td>571</td>
                          <td>592</td>
                          <td>616</td>
                          <td>642</td>
                        </tr>
                        <tr>
                          <th>Angle de tube de direction</th>
                          <td>65,6</td>
                          <td>65,6</td>
                          <td>65,6</td>
                          <td>65,6</td>
                        </tr>
                        <tr>
                          <th>Base arrière</th>
                          <td>457</td>
                          <td>457</td>
                          <td>457</td>
                          <td>457</td>
                        </tr>
                        <tr>
                          <th>Tube de direction</th>
                          <td>105</td>
                          <td>108</td>
                          <td>118</td>
                          <td>138</td>
                        </tr>
                        <tr>
                          <th>Empattement</th>
                          <td>1189</td>
                          <td>1210</td>
                          <td>1235</td>
                          <td>1262</td>
                        </tr>
                        <tr>
                          <th>Débattement arrière</th>
                          <td>140</td>
                          <td>140</td>
                          <td>140</td>
                          <td>140</td>
                        </tr>
                        <tr>
                          <th>Hauteur de l'enjambement</th>
                          <td>777</td>
                          <td>777</td>
                          <td>777</td>
                          <td>813</td>
                        </tr>
                        <tr>
                          <th>Taille de roue</th>
                          <td>29</td>
                          <td>29</td>
                          <td>29</td>
                          <td>29</td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                )}
                {!geometryToggle && (
                  <section className={styles.cubeStereoGeometrySvg}>
                    <Img
                      className={styles.image}
                      fluid={data.paralaneGeometry.childImageSharp.fluid}
                      alt="."
                    />
                  </section>
                )}
              </section>
            )}
          </section>
        </section>
      </main>
    </Layout>
  )
}

export default Route
